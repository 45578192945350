<template>
  <BaseButton :class="classes.wrapper" @click="handleClick">
    <picture>
      <source
        type="image/webp"
        srcset="/static/images/stories/stories.webp"
        height="42"
        width="115"
      >
      <img
        src="/static/images/stories/stories.png"
        alt="Отзывы"
        height="42"
        width="115"
      >
    </picture>
    <span :class="classes.text">
      Более 1 000 <br>
      положительных отзывов
    </span>
  </BaseButton>
</template>

<script setup lang="ts">
import { cx } from 'cva'

const classes = {
  wrapper: cx([
    'flex',
    'flex-col',
    'justify-center',
    'items-center',
    'w-full',
    'xl:items-center',
    'xl:flex-row',
    'xl:justify-center',
  ]),
  text: cx([
    'block',
    'font-bold',
    'text-[14px]/[16px]',
    'text-secondary',
    'text-center',
    'mt-2.5',
    'xl:text-[16px]/[19px]',
    'xl:ml-2.5',
    'xl:mt-0',
    'xl:text-left',
  ]),
}

const { $GLightbox } = useNuxtApp()

const openGallery = () => {
  const gallery = $GLightbox({
    touchNavigation: true,
    draggable: true,
    loop: true,
    slideEffect: 'slide',
    elements: Array.from({ length: 14 }, (_, index) => ({
      href: `https://static.shopat.ru/stories/${index + 1}.mp4`,
      type: 'video',
    })),
    plyr: {
      css: 'https://cdn.plyr.io/3.5.6/plyr.css',
      js: 'https://cdn.plyr.io/3.5.6/plyr.js',
      config: {
        controls: ['play', 'play-large', 'progress'],
        clickToPlay: true,
        hideControls: true,
        ratio: '9:16',
      },
    },
  })
  gallery.open()
}

const handleClick = () => {
  openGallery()
  emitYandexMetrika(YANDEX_METRIKA_GOALS.VIDEO)
}
</script>