<template>
  <div>
    <div :class="{ 'opacity-0': isLoading, 'opacity-1': !isLoading }" class="transition duration-500">
      <WidgetSectionHero />
    </div>
    <WidgetTradeInPreview />
    <WidgetSectionSalesHits />
    <WidgetSectionSaleBanner :class="[sectionStyles, 'container']" />
    <WidgetSectionPopularModels class="my-7" is-show-popular-categories />
    <WidgetSectionTradeIn class="mb-7" />
    <WidgetSectionMapContacts class="mb-7" />
    <WidgetSectionReviews />
    <WidgetSectionFaq />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { sectionStyles } from '@/utils'

const abStore = useAbStore()
const { isLoading } = storeToRefs(abStore)
</script>

<style>
.varioqub-antiflicker {opacity: 0 !important;}
</style>