<template>
  <div
    v-show="userStore.isShowTradeInPreview || !xlAndLarger"
    :class="[classes.root, 'container', 'xl:px-0', 'xl:max-w-none']"
  >
    <div :class="classes.wrapper">
      <div :class="classes.title">
        Купим ваш смартфон
      </div>
      <div :class="[classes.subtitle, !isVisible ? 'xl:hidden' : '']">
        Расскажите подробнее о вашем устройстве,<br>
        и узнайте его стоимость
      </div>
      <BaseProgressBar
        minified
        :current-step="1"
        :max-steps="10"
        :class="classes.progressBar"
      />
      <div :class="[classes.answers, !isVisible ? 'xl:hidden' : '']">
        <BaseButton
          v-for="modelGroup in activeModelGroups"
          :key="modelGroup.id"
          type="nuxt-link"
          intent="base-secondary"
          size="middle"
          :url="{ name: ROUTE_NAMES.TRADE_IN }"
          @click="handleClick(true)"
        >
          {{ modelGroup.name }}
        </BaseButton>
      </div>
      <div :class="classes.list">
        <span :class="classes.listSpan"> выкупаем дорого </span>
        <Icon
          name="common:point"
          size="5"
          class="text-icon-yellow"
        />
        <span :class="classes.listSpan"> за 15 минут </span>
        <Icon
          name="common:point"
          size="5"
          class="text-icon-yellow"
        />
        <span :class="classes.listSpan"> без коробки и документов </span>
      </div>
      <div :class="classes.icon" @click="handleClick(false)">
        <Icon
          v-if="isVisible"
          name="common:cross"
          size="24"
          class="text-disable"
        />
        <Icon
          v-else
          name="common:chevron"
          size="8"
          class="text-disable -rotate-90"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { cx } from 'cva'
import { ROUTE_NAMES } from '@/constants'

const classes = {
  root: cx([
    'xl:fixed',
    'xl:w-[344px]',
    'xl:bottom-[18px]',
    'xl:right-[34px]',
    'xl:z-[1000]',
  ]),
  wrapper: cx([
    'relative',
    'p-4',
    'my-[60px]',
    'rounded-md',
    'xl:m-0',
    'xl:p-6',
    'xl:invisible',
    'xl:animate-move',
    'bg-bg-main-primary',
    'xl:shadow-2xl',
  ]),
  title: cx(['text-[21px]/[27px]', 'font-bold', 'text-primary', 'mb-2']),
  subtitle: cx(['text-[14px]/[16px]', 'mb-4']),
  progressBar: 'mb-4',
  list: cx([
    'py-[5px]',
    'flex',
    'justify-between',
    'items-center',
    'flex-wrap',
    'text-[10px]',
  ]),
  listSpan: cx(['flex', 'items-center']),
  answers: cx(['grid', 'grid-cols-2', 'gap-1.5', 'mb-4']),
  icon: cx([
    'hidden',
    'cursor-pointer',
    'xl:absolute',
    'xl:inline',
    'xl:top-[15px]',
    'xl:right-[15px]',
  ]),
}

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const modelGroupsStore = useModelGroupsStore()
const userStore = useUserStore()

const isVisible = ref(true)
const activeModelGroups = computed(() =>
  modelGroupsStore.list?.filter(modelGroup => modelGroup.models.length),
)

const close = () => (userStore.isShowTradeInPreview = false)

const handleClick = (isRedirect: true | false) => {
  if (isRedirect) {
    emitYandexMetrika(YANDEX_METRIKA_GOALS.DEVICE)
  }
  else {
    isVisible.value = !isVisible.value
  }
  close()
}
</script>