<template>
  <div :class="!minified ? classes.root : ''">
    <div :class="[classes.count, minified ? classes.countMini : '']">
      Вопрос:
      <span> {{ currentStep }} из {{ maxSteps }} </span>
    </div>
    <div :class="classes.progress">
      <div :class="classes.progressBar" :style="{ width: widthPercent }"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { computed } from 'vue'

const props = defineProps({
  currentStep: {
    type: Number,
    required: true,
  },
  maxSteps: {
    type: Number,
    required: true,
  },
  minified: {
    type: Boolean,
    default: false,
  },
})

const classes = {
  root: cx(['mt-[35px]', 'mx-0', 'mb-1', 'xl:max-w-[800px]', 'xl:w-full']),
  progress: cx([
    'h-[10px]',
    'xl:h-[8px]',
    'bg-bg-main-thirdly',
    'rounded-lg',
    'overflow-hidden',
  ]),
  progressBar: cx(['h-full', 'bg-bg-yellow-yellow']),
  count: cx(['text-[16px]/[21px]', 'font-normal']),
  countMini: cx(['!text-[10px]/[1.3]', 'mb-1']),
}

const widthPercent = computed(() => {
  let percent = Math.ceil((100 * props.currentStep) / props.maxSteps)

  if (props.currentStep === props.maxSteps) {
    percent = 95
  }

  return percent + '%'
})
</script>